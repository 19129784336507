.themen-teaser-header-container h1 {
  margin-bottom: 10px;
  color: #0080bc;
  text-transform: uppercase;
  height: 31px;
  font-size: 26px;
  line-height: 32px;
}
.themen-teaser-header-container h2 {
  margin-bottom: 15px;
  color: #424242;
  text-transform: none;
  font-size: 26px;
  line-height: 32px;
}
.themen-teaser-header-container p {
  font-weight: 500;
}
.subtheme-teaser-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
}
.subtheme-teaser-inner-container {
  display: flex;
  flex-direction: column;
  background: #0080bc 0% 0% no-repeat padding-box;
  margin-bottom: 40px;
}
.subtheme-teaser-first {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 140px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}
.subtheme-teaser-first-text {
  margin-bottom: 0;
  margin-top: 10px;
  line-height: 24px;
  color: #ffffff;
}
.subtheme-teaser-first-header {
  letter-spacing: 0.23px;
  color: #ffffff;
  font-size: 23px;
  line-height: 28px;
  margin-bottom: 0px;
}
.subtheme-teaser-second {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 0 solid #00000003;
  background-color: #ffffff;
  box-shadow: 0px 0px 14px #00000029;
}
.subtheme-teaser-second-text {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  line-height: 24px;
  color: #424242;
}
.subtheme-teaser-second-link {
  margin-bottom: 15px;
  margin-left: 15px;
  margin-top: auto;
  align-self: flex-start;
}
.event-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0 50px 0;
}
.event-container-white {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0 50px 0;
}
.event-headline {
  color: #0080bc;
  font-size: 23px;
  margin-bottom: 30px;
  line-height: 24px;
  font-weight: 500;
}
.event-inner-container {
  display: flex;
  justify-content: space-between;
}
.event-image-container {
  width: 30%;
}
.event-image-container img {
  width: 100%;
  height: 260px;
  object-fit: cover;
}
.event-content {
  width: 66%;
}
.event-content .event-show-all-button {
  padding: 15px 11px;
  border-radius: 8px;
  background: #62B8E8 0% 0% no-repeat padding-box;
  color: #ffffff;
  margin-top: 10px;
  font-size: 15px;
  line-height: 18px;
  font-weight: initial;
  width: fit-content;
}
.event-content .event-show-all-button a {
  color: #ffffff;
}
.event-content .event-content-text {
  margin: 0;
  padding: 0 0 20px 0;
}
.event-content .event-content-text h3 a {
  font-size: 18px;
}
.themeteaser-anchor {
  display: block;
  position: relative;
  top: -60px;
  visibility: hidden;
}
.event-data {
  margin-bottom: 20px;
}
.event-data .event-data-headline {
  margin-bottom: 0;
  padding-bottom: 5px;
}
.event-data .event-data-date {
  font-size: 15px;
  margin-bottom: 0;
  padding-bottom: 5px;
}
.event-data .event-data-bookmarks {
  color: #0080bc;
  font-size: 15px;
  margin-bottom: 0;
}
.link.bookmark-add:before {
  width: 12px;
}
section:not(.menu) {
  height: initial;
}
.background-color-0 {
  background-color: #0080bc;
}
.background-color-1 {
  background-color: #62B8E8;
}
.background-color-2 {
  background-color: #d8ecfe;
}
.background-color-2 .subtheme-teaser-first-header {
  color: #0080bc;
}
.background-color-2 .subtheme-teaser-first-text {
  color: #0080bc;
}
.background-color-3 {
  background-color: #97A7B5;
}
@media screen and (max-width: 730px) {
  .subtheme-teaser-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .subtheme-teaser-inner-container {
    width: 100%;
  }
  .event-image-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .event-inner-container {
    flex-direction: column;
  }
  .event-content {
    width: initial;
    padding: 20px 0 0 0;
  }
  .event-data {
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 540px) {
  .subtheme-teaser-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media screen and (max-width: 367px) {
  .subtheme-teaser-second-link {
    top: 23px;
  }
  .subtheme-teaser-second {
    margin-top: 20px;
  }
}
