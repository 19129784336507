@import "_globals.less";


.themen-teaser-header-container {
  h1 {
    margin-bottom: 10px;
    color: @c-blue-200;
    text-transform: uppercase;
    height: 31px;
    font-size: 26px;
    line-height: 32px;
  }
  h2 {
    margin-bottom: 15px;
    color: @c-darkgray-400;
    text-transform: none;
    font-size: 26px;
    line-height: 32px;
  }
  p{
    font-weight: 500;
  }
}


.subtheme-teaser-container {
  display: grid;
  grid-template-columns: repeat(3,1fr);
  column-gap: 30px;
}

.subtheme-teaser-inner-container {
  display: flex;
  flex-direction: column;
  background: @c-blue-200 0% 0% no-repeat padding-box;
  margin-bottom: 40px;
}

.subtheme-teaser-first{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 140px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}
.subtheme-teaser-first-text {
  margin-bottom: 0;
  margin-top: 10px;
  line-height: 24px;
  color: @c-white;

}
.subtheme-teaser-first-header{
  letter-spacing: 0.23px;
  color: @c-white;
  font-size: 23px;
  line-height: 28px;
  margin-bottom: 0px;
}
.subtheme-teaser-second{
  display: flex;
  flex-direction: column;
  flex: 1;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 0 solid #00000003;
  background-color: @c-white;
  box-shadow: 0px 0px 14px #00000029;
}
.subtheme-teaser-second-text {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  line-height: 24px;
  color: @c-darkgray-400;
}
.subtheme-teaser-second-link{
  margin-bottom: 15px;
  margin-left: 15px;
  margin-top: auto;
  align-self: flex-start;
}

.event-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0 50px 0;
}
.event-container-white {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0 50px 0;
}

.event-headline {
  color: @c-blue-200;
  font-size: 23px;
  margin-bottom: 30px;
  line-height: 24px;
  font-weight: 500;
}

.event-inner-container {
  display: flex;
  justify-content: space-between;
}

.event-image-container {
  width: 30%;

  img {
    width: 100%;
    height: 260px;
    object-fit: cover;
  }
}
.event-content {
  width: 66%;


  .event-show-all-button {
    padding: 15px 11px;
    border-radius: 8px;
    background: @c-lightblue-50 0% 0% no-repeat padding-box;
    color: @c-white;
    margin-top: 10px;
    font-size: 15px;
    line-height: 18px;
    font-weight: initial;
    width: fit-content;

    a {
      color: @c-white;
    }
  }

  .event-content-text {
    margin: 0;
    padding: 0 0 20px 0;

    h3 a {
      font-size: 18px;
    }
  }
}

.themeteaser-anchor {
  display: block;
  position: relative;
  top: -60px;
  visibility: hidden;
}

.event-data {
  margin-bottom: 20px;

  .event-data-headline {
    margin-bottom: 0;
    padding-bottom: 5px;
  }
  .event-data-date {
    font-size: 15px;
    margin-bottom: 0;
    padding-bottom: 5px;
  }
  .event-data-bookmarks{
    color: @c-blue-200;
    font-size: 15px;
    margin-bottom: 0;
  }
}

.link.bookmark-add:before {
  width: 12px;
}


section:not(.menu) {
  height: initial;
}








.background-color-0{
  background-color: @c-blue-200;
}
.background-color-1{
  background-color: @c-lightblue-50;
}
.background-color-2{
  background-color: @c-lightblue-400;

  .subtheme-teaser-first-header {
    color: @c-blue-200;
  }
  .subtheme-teaser-first-text {
    color: @c-blue-200;
  }
}
.background-color-3{
  background-color: @c-gray;
}


@media screen and (max-width: 730px) {

  .subtheme-teaser-container {
    grid-template-columns: repeat(2,1fr);
  }


  .subtheme-teaser-inner-container {
    width: 100%;
  }

  .event-image-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .event-inner-container {
    flex-direction: column;
  }


  .event-content {
    width: initial;
    padding: 20px 0 0 0;

    .event-content-text {
     // margin-top: -20px;
    }
  }

  .event-data{
    width: 100%;
    margin: 0;
    margin-bottom: 20px
  }

}


@media screen and (max-width: 540px) {


  .subtheme-teaser-container {
    grid-template-columns: repeat(1,1fr);
  }


}


@media screen and (max-width: 367px) {

  .subtheme-teaser-second-link {
    top: 23px;
  }


  .subtheme-teaser-second {

    margin-top: 20px;
  }

}























